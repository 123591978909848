import { createSlice } from "@reduxjs/toolkit";
import { GetSummaryReportFiltersAction,GetCustomSummaryReportAction,GetHourlySummaryReportAction,ExportSummaryReportAction } from "./SummaryReportAction";
import { format } from "date-fns";
import { calculateDates } from "@/app/_utils/others/Helpers";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});


const getCurrentDateRange = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = format(yesterday, "yyyyMMdd");
  return { from_date: formattedYesterday, to_date: formattedYesterday };
};


const getInitialTabState = () => ({
  duration: { date: true, month: false },
  indicator: {
    feedProvider: true,
    ad_creatives: true,
    adset: true,
    channel_identifier: true,
    media_buyer: true,
    impression: true,
    clicks: true,
    channel_name__2: true,
  },
  calculation: {
    rpc: true,
    rpm: true,
    spend: true,
    revenue: true,
    cpc: true,
    margin: true,
    margin_percent: true,
    roas: true,
  },
  filter: {
    feed_provider: true,
    media_buyer: true,
    channel: true,
    channel_2: true,
    channel_3: true,
  },
  filterOptions: {
    feed_provider: [],
    media_buyer: [],
    channel: [],
    channel_2: [],
    channel_3: [],
  },
  selectedOptions: {
    channel: "",
    channel_2: "",
    channel_3: "",
    feed_provider: "",
    media_buyer: "",
  },

  // date_range: getCurrentDateRange(),
  date_range: {
    from_date: getCurrentDateRange().from_date,
    to_date: getCurrentDateRange().to_date,
    selectedOption: "Yesterday",
  },
});


  const formatOpChennal3 = (options) =>
    options.map((option) => ({
      value: option.identifier_name,
     label: option.identifier_name ,
    }));

  

    const formatOpFeed = (options) =>
    options.map((option) => ({
      value: option.value,
     label: option.label ,
    }));

    const formatOpMedibuyer = (options) =>
    options.map((option) => ({
      value: option.user_id,
     label: option.name ,
    }));



const initialState = {
  Getfillterdata: getInitialStatus(),
  GetCustomSummarydata: getInitialStatus(),
  GetHourlySummarydata:getInitialStatus(),
  GetExportdata:getInitialStatus(),
  SummaryFeedData:{},
  SummaryStatsData:{},
  HourlyReportData:{},
  ExportAllData:{},
  totalRecords:0,
  HourlytotalRecords:0,

  filters: {
    1: getInitialTabState(),
    2: getInitialTabState(),
    3: getInitialTabState(),
    4: getInitialTabState(),
    5: getInitialTabState(),
  },
  activeTab: 1,
  tabNames: {
    1: "Custom Report 1",
    2: "Custom Report 2",
    3: "Custom Report 3",
    4: "Custom Report 4",
    5: "Custom Report 5",
  },
};

const SummaryReportSlice = createSlice({
  name: "GetSummary",
  initialState,

  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setFilter: (state, action) => {
      const { tabId, filterKey, filterValue } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId][filterKey] = filterValue;
        // console.log("Updated filter in Redux:", state.filters[tabId]);
      }
    },
    setFilterOption: (state, action) => {
      const { tabId, filterKey, filterOptions } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].filterOptions[filterKey] = filterOptions;
        // console.log(
        //   "Updated filter options in Redux:",
        //   state.filters[tabId].filterOptions
        // );
      }
    },
    setSelectedOptions: (state, action) => {
      const { tabId, selectedOptions } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].selectedOptions = selectedOptions;
        // console.log(
        //   "Updated selected options in Redux:",
        //   state.filters[tabId].selectedOptions
        // );
      }
    },

    




    setFiltersForActiveTab: (state, action) => {
      state.filters[state.activeTab] = action.payload;
      // console.log(
      //   "Updated filters for active tab in Redux:",
      //   state.filters[state.activeTab]
      // );
    },
    setFilterData: (state, action) => {
      const { filters } = action.payload;
      state.filters = filters;
      // console.log("Updated filters data in Redux:", state.filters);
    },
    renameTab: (state, action) => {
      const { tabId, newName } = action.payload;
      if (state.tabNames[tabId]) {
        state.tabNames[tabId] = newName; 
      }
    },
    setTabNames: (state, action) => {
      state.tabNames = action.payload; 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetSummaryReportFiltersAction.pending, (state) => {
      state.Getfillterdata.isLoading = true;
    });
    builder.addCase(
      GetSummaryReportFiltersAction.fulfilled,
      (state, action) => {
        state.Getfillterdata.isLoading = false;
        state.Getfillterdata.isSuccess = true;

        const { response } = action.payload;
        const updatedFilters = {};
        const tabIds = [1, 2, 3, 4, 5];

        tabIds.forEach((tabId) => {
          const tabData = response.data?.[0]?.[`custom_report_${tabId}`] || {};
          const dateRangeData = tabData.date_range || {
          selectedOption: "Yesterday",
          from_date: "",
          to_date: "",
        };
        let dateRange;

        if (dateRangeData.selectedOption === "Custom Range") {
          dateRange = {
            from_date: dateRangeData.from_date || "",
            to_date: dateRangeData.to_date || "",
            selectedOption: "Custom Range",
          };
        } else {
          dateRange = calculateDates(dateRangeData.selectedOption || "Yesterday");
        }



          updatedFilters[tabId] = {
            duration:
              response.data?.[0]?.[`custom_report_${tabId}`]?.duration ||
              getInitialTabState().duration,
            indicator:
              response.data?.[0]?.[`custom_report_${tabId}`]?.indicator ||
              getInitialTabState().indicator,
            calculation:
              response.data?.[0]?.[`custom_report_${tabId}`]?.calculation ||
              getInitialTabState().calculation,
            filter:
              response.data?.[0]?.[`custom_report_${tabId}`]?.filter ||
              getInitialTabState().filter,
             
            filterOptions: {
              feed_provider:formatOpFeed(response.feed_provider) || [],
              media_buyer: formatOpMedibuyer(response.media_buyer || []), 
              channel: response.uniqueChannelNames || [],
              channel_2: response.uniqueChannelNames2 || [],
              channel_3: formatOpChennal3(response.uniqueChannelNames3) || [],
            },

            selectedOptions: tabData.selected_options || getInitialTabState().selectedOptions,
            // date_range: tabData.date_range || getCurrentDateRange(),
            date_range: dateRange,
          };
          // console.log(`Tab ${tabId} date range:`, updatedFilters[tabId].date_range);
        });
        // console.log(' console.log(response.media_buyer || [])',formatOpMedibuyer(response.media_buyer  || []))



        state.filters = updatedFilters;
        state.tabNames = tabIds.reduce((acc, tabId) => {
          acc[tabId] =
            response.data?.[0]?.[`custom_report_${tabId}`]?.report_name ||
            `Custom Report ${tabId}`;
          return acc;
        }, {});
      }
    );
    builder.addCase(GetSummaryReportFiltersAction.rejected, (state, action) => {
      state.Getfillterdata.isLoading = false;
      state.Getfillterdata.isError = true;
      state.Getfillterdata.errorMsg = action.payload?.response;
      state.Getfillterdata.errorResponse = action.payload;
    });
    // Custom Summary  report getdata 
    builder.addCase(GetCustomSummaryReportAction.pending, (state) => {
        state.GetCustomSummarydata.isLoading = true;
      });
      builder.addCase(GetCustomSummaryReportAction.fulfilled, (state, action) => {
        state.GetCustomSummarydata.isLoading = false;
        state.GetCustomSummarydata.isSuccess = true;
        state.GetCustomSummarydata = action.payload;
        state.SummaryFeedData = action.payload?.finalData;
        state.SummaryStatsData = action.payload?.sumTotal;
        state.totalRecords = action.payload?.total_length;
        // state.ExportAllData = action.payload?.result;
      });
      builder.addCase(GetCustomSummaryReportAction.rejected, (state, action) => {
        state.GetCustomSummarydata.isLoading = false;
        state.GetCustomSummarydata.isError = true;
        state.GetCustomSummarydata.errorMsg = action.payload?.response;
        state.GetCustomSummarydata.errorResponse = action.payload;
      });

      // GetHourlySummarydata
      builder.addCase(GetHourlySummaryReportAction.pending, (state) => {
        state.GetHourlySummarydata.isLoading = true;
      });
      builder.addCase(GetHourlySummaryReportAction.fulfilled, (state, action) => {
        state.GetHourlySummarydata.isLoading = false;
        state.GetHourlySummarydata.isSuccess = true;
        state.GetHourlySummarydata = action.payload;
        state.HourlyReportData = action.payload;
        state.HourlytotalRecords = action.payload?.total_length;
  
      });
      builder.addCase(GetHourlySummaryReportAction.rejected, (state, action) => {
        state.GetHourlySummarydata.isLoading = false;
        state.GetHourlySummarydata.isError = true;
        state.GetHourlySummarydata.errorMsg = action.payload?.response;
        state.GetHourlySummarydata.errorResponse = action.payload;
      });
           // GetHourlySummarydata
       builder.addCase(ExportSummaryReportAction.pending, (state) => {
        state.GetExportdata.isLoading = true;
      });
      builder.addCase(ExportSummaryReportAction.fulfilled, (state, action) => {
        state.GetExportdata.isLoading = false;
        state.GetExportdata.isSuccess = true;
        state.GetExportdata = action.payload;
        state.ExportAllData = action.payload;
  
      });
      builder.addCase(ExportSummaryReportAction.rejected, (state, action) => {
        state.GetExportdata.isLoading = false;
        state.GetExportdata.isError = true;
        state.GetExportdata.errorMsg = action.payload?.response;
        state.GetExportdata.errorResponse = action.payload;
      });
  },
});

export const {
  setActiveTab,
  setFilter,
  setFilterOption,
  setSelectedOptions,
  setFiltersForActiveTab,
  setFilterData,
  renameTab,
  setTabNames,
} = SummaryReportSlice.actions;

export default SummaryReportSlice.reducer;
